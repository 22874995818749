<template>
  <section>
    <validation-observer ref="rules">
    <b-card class="ll-card">
      <b-card-header>
        <b-card-title>{{ $t('Business Profile') }}</b-card-title>
        <div class="ll-required">* {{ $t('Required Fields') }}</div>
      </b-card-header>
      <b-card-body>
        <b-row>
          <b-col md="3">
            <b-form-group
              label=""
              label-for="name"
            >
              <validation-provider
                #default="{ errors }"
                name="name"
                rules="required"
              >
              <label class="ll-boldText">{{ $t('Code') }}</label>
              <b-form-input
                id="name"
                v-model="info.name"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="3">
            <b-form-group
              :label="$t('Company Name')"
              label-for="name"
            >
              <b-form-input
                v-model="info.displayName"
              />
            </b-form-group>
          </b-col>
          <b-col md="3">
            <b-form-group
              label=""
              label-for="name"
            >
              <validation-provider
                #default="{ errors }"
                name="Company Type"
                rules="required"
              >
              <label class="ll-boldText">{{$t('Company Type')}}</label>
              <v-select
                :options="typeList"
                label="codeDescription"
                @input="selType"
                v-model="type"
              >
              </v-select>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="3">
            <!-- <b-form-group
              label="Phone"
              label-for="name"
            >
              <b-form-input
                v-model="info.orgPhone"
              />
            </b-form-group> -->
            <b-form-group
              :label="$t('Sub Type')"
              label-for="name"
            >
              <v-select
                :options="subtypeList"
                label="value"
                @input="selSubtype"
                v-model="info.subType">
              </v-select>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="3">
            <b-form-group
              label=""
              label-for="email"
            >
              <validation-provider
                #default="{ errors }"
                name="email"
                rules="required|email"
              >
              <label class="ll-boldText">{{ $t('Email') }}</label>
              <b-form-input
                id="email"
                v-model="info.orgEmail"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
            </b-form-group>
            <!-- <b-form-group
              label="Creation Time"
              label-for="name"
            >
              <flat-pickr
                value=""
                class="form-control ll-pickr"
                :config="{ enableTime: false,dateFormat: 'Y-m-d H:i:ss'}"
              />
            </b-form-group> -->
          </b-col>
          <b-col md="3">
            <b-form-group
              :label="$t('Address')"
              label-for="name"
            >
              <b-form-input
                v-model="info.address"
              />
            </b-form-group>
          </b-col>
          <b-col md="3">
            <b-form-group
              :label="$t('Country')"
              label-for="name"
            >
              <b-form-input
                v-model="info.country"
              />
            </b-form-group>
          </b-col>
          <b-col md="3">
            <b-form-group
              :label="$t('Country code')"
              label-for="name"
            >
              <b-form-input
                v-model="info.countryCode"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="3">
            <b-form-group
              :label="$t('Phone')"
              label-for="name"
            >
              <b-form-input
                v-model="info.orgPhone"
              />
            </b-form-group>
          </b-col>
          <b-col md="3">
            <b-form-group
              :label="$t('SP vendor')"
              label-for="sp"
            >
              <v-select
                :options="spList"
                label="agent"
                @input="selsp"
                v-model="sp"
                multiple
              ></v-select>
            </b-form-group>
          </b-col>
          <b-col md="3">
            <b-form-group
              label=""
              label-for="sk"
            >
              <validation-provider
                #default="{ errors }"
                name="KE storer code"
                rules="required"
              >
                <label class="ll-boldText">{{ $t('KE storer code') }}</label>
                <b-form-input
                  v-model="info.storeCode"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="3">
            <b-form-group
              :label="$t('Status Webhook URL')"
              label-for="name"
            >
              <b-form-input
                v-model="info.statusUrl"
                type="url"
              />
            </b-form-group>
          </b-col>
          <b-col md="3" v-if="false">
            <b-form-group
              label="Email"
              label-for="email"
            >
              <validation-provider
                #default="{ errors }"
                name="email"
                rules="required|email"
              >
              <b-form-input
                id="email"
                v-model="info.orgEmail"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="3">
            <!-- <b-form-group
              :label="$t('Mall Name')"
              label-for="name"
              v-if="isShowMall"
            >
              <v-select
                :options="mallList"
                label="codeDescription"
                @input="selMall"
                v-model="mallsName"
                :disabled="isShowMall">
              </v-select>
            </b-form-group> -->
            <b-form-group
              label=""
              label-for="s"
              v-if="!isShowMall"
            >
              <validation-provider
                #default="{ errors }"
                name="Mall Name"
                rules="required"
              >
                <label class="ll-boldText">{{ $t('Mall Name') }}</label>
                <v-select
                  :options="mallList"
                  label="codeDescription"
                  @input="selMall"
                  v-model="mallsName"
                >
                </v-select>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="3">
            <!-- <b-form-group
              :label="$t('Mall to Home TKID Prefix')"
              label-for="name"
              v-if="isShowMall"
            >
              <b-form-input :disabled="isShowMall" v-model="info.trackingNumberPrefix"/>
            </b-form-group> -->
            <b-form-group
              label=""
              label-for="s"
              v-if="!isShowMall"
            >
              <validation-provider
                #default="{ errors }"
                name="Mall to Home TKID Prefix"
                rules="required"
              >
                <label class="ll-boldText">{{ $t('Mall to Home TKID Prefix') }}</label>
                <b-form-input v-model="info.trackingNumberPrefix"/>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="3">
            <b-form-group
              :label="$t('Sales Name')"
              label-for="name"
            >
              <b-form-input
                v-model="info.salesName"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
    <b-card>
      <b-card-header>
        <b-card-title>{{ $t('Merchant') }}</b-card-title>
      </b-card-header>
      <b-card-body>
        <!-- <vxe-toolbar>
          <template #buttons>
            <vxe-button icon="fa fa-plus" @click="insertEvent()">+ Add Merchant</vxe-button>
            <vxe-button @click="validEvent">快速校验</vxe-button>
          </template>
        </vxe-toolbar> -->
        <b-button
          class="ll-addbtn"
          variant="info"
          @click="insertEvent()"
        >
          <span>{{ $t('Add') }}</span>
        </b-button>
        <vxe-table
          border
          show-overflow
          keep-source
          ref="xTable"
          max-height="400"
          :data="items"
          :edit-config="{trigger: 'click', mode: 'row', showStatus: true}"
          empty-text="No matching records found"
          v-if="isShow"
        >
          <!-- <vxe-table-column type="checkbox" width="60"></vxe-table-column> -->
          <vxe-table-column field="merchantCode" :title="$t('Merchant code')" ></vxe-table-column>
          <vxe-table-column field="userName" :title="$t('Merchant name')"  >
            <template #default="{ row }">
              <!-- <validation-provider
                #default="{ errors }"
                name="Merchant name"
                rules="required"
              > -->
                <b-form-input v-model="row.userName"/>
                <!-- <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider> -->
            </template>
          </vxe-table-column>
          <!-- <vxe-table-column field="selfpick" title="SP vendor"  >
            <template #default="{ row }">
              <b-badge
                pill
                class="ll-bdg"
                variant="light-primary"
                v-for="(item, index) in row.selfpick"
                :key="index"
              >
                <span>{{item}}</span>
              </b-badge>
            </template>
          </vxe-table-column> -->
          <vxe-table-column
            field="email"
            :title="$t('Email')"
          >
            <template #default="{ row }">
              <!-- <validation-provider
                #default="{ errors }"
                name="email"
                rules="required|email"
              > -->
                <b-form-input
                  v-model="row.email"
                />
                <!-- <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider> -->
            </template>
          </vxe-table-column>
          <vxe-table-column
            field="userAccount"
            :title="$t('Enable user account')"
          >
            <template #default="{ row }">
              <b-form-checkbox
                v-model="row.userAccount"
                :class="row.userAccount ? 'custom-control-info': 'custom-control-secondary'"
              >
              </b-form-checkbox>
            </template>
          </vxe-table-column>
          <vxe-table-column field="isEnabled" :title="$t('Active')">
            <template #default="{ row }">
              <b-form-checkbox
                v-model="row.isEnabled"
                :class="row.isEnabled ? 'custom-control-info': 'custom-control-secondary'"
              >
              </b-form-checkbox>
              <!-- <b-badge
                pill
                class="ll-badge"
                :variant="row.isEnabled === 'true' ? 'light-info': 'light-primary'"
              >
                <span>{{row.isEnabled === 'true' ? 'Active' : 'Inactive'}}</span>
              </b-badge> -->
            </template>
          </vxe-table-column>
          <vxe-table-column :title="$t('Action')" width="100" show-overflow>
            <template #default="{ row }" >
              <!-- <vxe-button type="text" icon="fa fa-edit" @click="editEvent(row)">
                <feather-icon icon="EditIcon" />
              </vxe-button> -->
              <vxe-button type="text" icon="" @click="removeEvent(row)">
                <feather-icon icon="Trash2Icon" />
              </vxe-button>
            </template>
          </vxe-table-column>
        </vxe-table>
      </b-card-body>
    </b-card>
    <!-- WMS Integration -->
    <wms v-if="isShowwms" @wmsInfo="wmsInfo"></wms>

    <div class="ll-subBtn">
      <b-button
        variant="info"
        @click.prevent="validationForm"
      >
        {{ $t('Submit') }}
      </b-button>
    </div>
    </validation-observer>

  </section>
</template>

<script>
import {
  BRow, BCol, BCard, BCardBody, BCardHeader, BCardTitle, BFormGroup, BFormInput, BButton,
  BFormCheckbox, BFormRadio, BModal, VBModal, BBadge,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import '@core/scss/vue/libs/vue-flatpicker.scss'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, email } from '@validations'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import wms from './wms.vue'

export default {
  components: {
    wms,
    BRow,
    BCol,
    BCard,
    BCardBody,
    BCardHeader,
    BCardTitle,
    BFormGroup,
    BFormInput,
    vSelect,
    flatPickr,
    BButton,
    BFormCheckbox,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    ValidationProvider,
    ValidationObserver,
    BFormRadio,
    BModal,
    BBadge,
    BCardActions,
  },
  directives: {
    'b-modal': VBModal,
  },
  data() {
    return{
      required,
      email,
      typeList: [],
      type: '',
      info: {
        name: '',
        displayName: null,
        description: null,
        address: null,
        country: null,
        countryCode: null,
        orgType: null,
        orgEmail: null,
        orgPhone: null,
        orgIcon: null,
        merchantList: null,
        subType: null,
        storeCode: null,
        spVendor: null,
        statusUrl: null,
        mallsName: null,
        trackingNumberPrefix: null,
        fullfillmentInfoList: null,
      },
      mallsName: null,
      items: [
        { userName: null, email: null, userAccount: false, isEnabled: false }
      ],
      validRules: {
        // merchantCode: [
        //   { required: true, message: 'The Merchant code field is required' }
        // ],
        // merchantName: [
        //   { required: true, message: 'The Merchant name field is required' }
        // ],
      },
      subtypeList: ['E-Platform'],
      spList: [],
      sp: '',
      formItems: [],
      showEdit: false,
      isEnabled: null,
      formData: {
        merchantCode: null,
        userName: null,
        selfpick: null,
        isEnabled: null,
      },
      collapsed: true,
      isShow: false,
      isShowMall: true,
      mallList: [],
      isShowwms: false,
    }
  },
  created() {},
  mounted() {
    this.queryOrgType()
    this.querysp()
  },
  methods: {
    wmsInfo(val) {
      this.info.fullfillmentInfoList = val
    },
    getMall() {
      this.$http.get('/masterData/findAllByDataType', { params: { data_type: 'mallName'}}).then(res => {
        this.mallList = res.data.data
      })
    },
    selMall(d) {
      if (d) {
        this.mallsName = d.codeDescription
        this.info.mallsName = d.codeName
      } else {
        this.mallsName = null
        this.info.mallsName = null
      }
    },
    cardAction() {
      // this.isShow = !this.isShow
    },
    addMerchant () {
      setTimeout(() => {
        const $table = this.$refs.xTable
        this.showEdit = false
        // console.log('add', this.formData)
        $table.insert(this.formData)
        // if (this.selectRow) {
        //   this.$XModal.message({ content: '保存成功', status: 'success' })
        //   Object.assign(this.selectRow, this.formData)
        // } else {
        //   this.$XModal.message({ content: '新增成功', status: 'success' })
        //   $table.insert(this.formData)
        // }
      }, 500)
    },
    editEvent(row) {

    },
    removeEvent (row) {
      this.box = ''
      this.$bvModal
      .msgBoxConfirm('Please confirm that you want to delete .', {
        title: 'Handle',
        size: 'sm',
        okVariant: 'primary',
        okTitle: 'Yes',
        cancelTitle: 'No',
        cancelVariant: 'outline-secondary',
        hideHeaderClose: false,
        centered: true,
      })
      .then(value => {
        if (value) {
          this.box = value
          this.$refs.xTable.remove(row)
        }
      })
    },
    editClosedEvent ({ row, column }) {
      // console.log(row, column)
      const $table = this.$refs.xTable
      const field = column.property
      const cellValue = row[field]
      // console.log(row)
    },
    // 插入新一行
    async insertEvent (row) {
      this.isShow = true
      const $table = this.$refs.xTable
      const record = {}
      const { row: newRow } = await $table.insertAt(record, row)
      await $table.setActiveCell(newRow)
    },
    insertEvent1 () {
      // this.selectRow = null
      this.showEdit = true
    },
    // 校验 merchant
    async validEvent () {
      const $table = this.$refs.xTable
      const errMap = await $table.validate().catch(errMap => errMap)
      if (!errMap && this.items[0].merchantCode !== null) {
        console.log('校验通过！', errMap)
      } else {
        console.log('校验不通过！', errMap)
      }
    },
    queryOrgType() {
      // this.$http.get('admin/settings/organization/getMasterListByType?dataType=orgType').then(res => {
      //   this.typeList = res.data.data
      // })
      this.$http.get('/masterData/findAllByDataType', { params: { data_type: 'orgType'}}).then(res => {
        this.typeList = res.data.data
      })
    },
    selType(d) {
      // this.type = t.value
      // this.info.orgType = t.value
      if (d) {
        this.info.orgType = d.codeName
        this.type = d.codeDescription
        if (d.codeName === 'MALL_TO_HOME') {
          this.isShowMall = false
          this.getMall()
        } else {
          this.isShowMall = true
        }
        if (d.codeName == 'FULLFILLMENT_CUSTOMER') {
          this.isShowwms = true
        } else {
          this.isShowwms = false
        }
      } else {
        this.info.orgType = null
        this.type = null
      }
    },
    selSubtype(d) {
      this.info.subType = d ? d : null
    },
    querysp() {
      this.$http.get('admin/settings/organization/getDistinctAgent').then(res =>{
        this.spList = res.data.data
      })
    },
    selsp(d) {
      if (d) {
        const arr = []
        d.map(i =>{
          arr.push(i.agent)
        })
        this.sp = d
        // this.formData.selfpick = arr
        this.info.spVendor = arr
      } else {
        this.info.spVendor = []
      }
    },
    selActive(d) {
      console.log(d)
    },
    createCustomer() {
      this.$http.post('admin/settings/organization/createOrganization', this.info).then(res => {
        if (res.data.code === '200') {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Create customer Success',
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
          this.$router.push({ name: 'customer-list' })
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: res.data.message,
              icon: 'XIcon',
              variant: 'danger',
            },
          })
        }
      })
    },
    submit() {
      this.$refs.rules.validate().then(success => {
        if (success) {
          this.createCustomer()
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Please check the data',
              icon: 'XIcon',
              variant: 'danger',
            },
          })
          return false
        }
        return true
      })
    },
    validationForm() {
      const $table = this.$refs.xTable
      if ($table !== undefined) {
        const arr = []
        $table.tableData.map(i =>{
          const obj = {}
          obj.merchantCode = ''
          obj.userName = i.userName
          obj.email = i.email
          obj.userAccount = i.userAccount
          obj.isEnabled = i.isEnabled
          arr.push(obj)
        })
        this.info.merchantList = arr
        this.submit()
      } else {
        this.info.merchantList = []
        this.submit()
      }
    },
    validationForm1() {
      this.$refs.rules.validate().then(success => {
        if (success) {
          this.addMerchant()
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Please check the data',
              icon: 'XIcon',
              variant: 'danger',
            },
          })
          return false
        }
        return true
      })
    },
  },
}
</script>

<style scoped>
.ll-subBtn{
  text-align: right;
  height: 50px;
}
.ll-radio{
  display: inline-block;
  margin-right: 10px;
}
.ll-card{
  margin-bottom: 1rem;
}
.ll-bdg{
  margin-right: 0.2rem;
}
.ll-addbtn{
  margin-bottom: 10px;
}
</style>
<style>
.vxe-modal--body{
  height: auto;
  overflow: unset ;
}
.vxe-modal--wrapper.type--alert .vxe-modal--body,
.vxe-modal--wrapper.type--alert .vxe-modal--body .vxe-modal--content,
.vxe-modal--wrapper.type--confirm .vxe-modal--body,
.vxe-modal--wrapper.type--confirm .vxe-modal--body .vxe-modal--content,
.vxe-modal--wrapper.type--modal .vxe-modal--body,
.vxe-modal--wrapper.type--modal .vxe-modal--body
.vxe-modal--content{
  overflow: unset;
}
.vxe-table--render-default .vxe-body--column.col--ellipsis>.vxe-cell, .vxe-table--render-default .vxe-footer--column.col--ellipsis>.vxe-cell, .vxe-table--render-default .vxe-header--column.col--ellipsis>.vxe-cell {
  max-height: 60px;
  padding: 5px;
}
/* .vxe-table--body-wrapper, .vxe-table--fixed-left-body-wrapper, .vxe-table--fixed-right-body-wrapper{
  overflow-x: hidden;
} */
</style>